import * as React from "react"
import Container from "../components/Container"
import Header from "../components/Header"
import Button from "../components/Button"

export default function Confirmation() {
  React.useEffect(() => {
    (window?.dataLayer || []).push({
      event: "hoodie_anfrage",
    })
  }, [])

  return (
    <div className="antialiased text-gray-900">
      <Header />
      <Container>
        <div className="h-screen flex flex-1 flex-col items-center justify-center">
          <span className="text-4xl" role="img" aria-label="celebrate-emoji">
            🎉
          </span>
          <span className="mt-2 text-black text-xl font-bold">
            Erfolgreich abgesendet
          </span>

          <span className="mt-4 text-black text-center">
            Danke für Deine Anfrage! Wir prüfen diese innerhalb der nächsten 1-2
            Arbeitstagen und melden uns bei Dir.
          </span>

          <Button
            className="mt-8"
            type="button"
            style={{ width: "auto" }}
            onClick={() => window.open("https://swissbadge.com/shop", "_self")}
            label="Zum Swissbadge Shop"
          />
        </div>
      </Container>
    </div>
  )
}
